import { darken } from 'polished';
import styled, { css } from 'styled-components';

function getContrastColor(bgColor: string) {
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 130 ? 'black' : '#ffffff';
}

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['point', 'color'].includes(prop),
})<{
    point?: boolean;
    color?: string;
}>(
    ({ theme, point, color }) => css`
        padding: 0.8rem 1rem;
        background: ${color ? darken(0.1, color) : theme.palette.color.secondary.main};
        color: ${color ? getContrastColor(darken(0.2, color)) : theme.palette.common.textInverse};
        border-radius: 8px;
        box-shadow: ${theme.shadow.large};
        position: relative;

        ${point &&
        css`
            &:after {
                content: '';
                width: 12px;
                height: 12px;
                display: block;
                background: ${theme.palette.color.white.main};
                border: 2px solid ${theme.palette.color.secondary.main};
                border-radius: 20px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -20px;
            }
        `}

        .tooltip-date {
            opacity: 0.5;
            font-size: 12px;
            font-weight: 700;
        }
        .tooltip-price {
            font-size: 14px;
            font-weight: 700;
        }
    `,
);

export const Date = styled.div(
    ({ theme }) => css`
        opacity: 0.6;
        font-size: ${theme.text.size.vsmall.fontSize};
        font-weight: 700;
    `,
);

export const Value = styled.div(
    ({ theme }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        font-weight: 700;
    `,
);

export const Percentafe = styled.div(
    ({ theme }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        font-weight: 500;
    `,
);

export const Percentage = styled.div``;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
    gap: 1.6rem;
`;

export const Type = styled.div(
    ({ theme }) => css`
        opacity: 0.6;
        font-size: ${theme.text.size.vsmall.fontSize};
        font-weight: 700;
    `,
);

export const Unit = styled.div(
    ({ theme }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        font-weight: 700;
    `,
);

export const Amount = styled.div(
    ({ theme }) => css`
        font-size: ${theme.text.size.xsmall.fontSize};
        font-weight: 700;
        opacity: 0.6;
    `,
);
