import { observer } from 'mobx-react-lite';
import React from 'react';
import { ValueAmount } from '../../../value/amount/value-amount';
import { Date, Value, Wrapper, Percentage, Header } from './chart-tooltip.styled';

export interface TotalValueTooltipProps {
    label: string;
    value?: string;
    amount?: number;
    currency?: string;
    percentage?: string;
}

export const TotalValueChartTooltip: React.FC<TotalValueTooltipProps> = observer((props: TotalValueTooltipProps) => {
    const { label, value, amount, currency, percentage } = props;

    return (
        <Wrapper>
            <Header>
                <Date>{label}</Date>
            </Header>
            {value ? <Value>{value}</Value> : null}
            {percentage && <Percentage>({percentage} %)</Percentage>}
            {amount && currency ? <ValueAmount amount={amount} currency={currency} mode="pure" hidable /> : null}
        </Wrapper>
    );
});
