import React from 'react';
import Skeleton from '../../skeleton';
import { Stack } from '../../../stack/stack';

export const SkeletonChartBar: React.FC = () => {
    return (
        <Stack fullWidth justifyContent="space-between" alignItems="flex-end">
            <Skeleton width={6} height={10} />
            <Skeleton width={6} height={20} />
            <Skeleton width={6} height={30} />
            <Skeleton width={6} height={25} />
            <Skeleton width={6} height={20} />
            <Skeleton width={6} height={34.6} />
            <Skeleton width={6} height={22} />
            <Skeleton width={6} height={18} />
            <Skeleton width={6} height={24} />
            <Skeleton width={6} height={22} />
        </Stack>
    );
};
