import React from 'react';
import { Headline } from '../headline/headline';
import { Action, Wrapper } from './page-headline.styled';
import { GapSizeType } from '../../../theme/type/gap-type';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';

interface PageHeadlineInterface {
    title: React.ReactChild | string;
    action?: React.ReactElement;
    mobileBreakUnder?: BreakpointSizeType;
    gapTop?: GapSizeType;
    gapBottom?: GapSizeType;
}

export const PageHeadline: React.FC<PageHeadlineInterface> = ({
    title,
    action,
    gapBottom,
    gapTop,
    mobileBreakUnder,
}) => {
    return (
        <Wrapper mobileBreakUnder={mobileBreakUnder} gapTop={gapTop} gapBottom={gapBottom}>
            <Headline level="1" gap="none">
                {title}
            </Headline>
            <Action mobileBreakUnder={mobileBreakUnder}>{action && action}</Action>
        </Wrapper>
    );
};
