import styled, { css, DefaultTheme } from 'styled-components';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';
import { getOpacity } from '../../../theme/utils/get-rgb';

const getCssSkeletonWidthSize = (width: string | number) => {
    if (typeof width === 'string') {
        return `
            width: ${width};
            min-width: ${width};
        `;
    }

    return `  
        width: ${width}rem;
        min-width: ${width}rem;
    `;
};

const getResponsiveSkeletonWidthSize = (size: object, theme: DefaultTheme) => {
    return Object.entries(size).map(([breakpointKey, iconSizeKey], index) => {
        if (index === 0) {
            return css`
                @media screen and (min-width: 0px) {
                    ${getCssSkeletonWidthSize(iconSizeKey as string | number)}
                }
            `;
        }

        return css`
            @media ${theme.breakpoints[breakpointKey as BreakpointSizeType].min} {
                ${getCssSkeletonWidthSize(iconSizeKey as string | number)}
            }
        `;
    });
};

const getCssSkeletonHeightSize = (height: string | number) => {
    if (typeof height === 'string') {
        return `
            height: ${height};
            min-height: ${height};
        `;
    }

    return `
        height: ${height}rem;
        min-height: ${height}rem;
    `;
};

const getResponsiveSkeletonHeightSize = (size: object, theme: DefaultTheme) => {
    return Object.entries(size).map(([breakpointKey, iconSizeKey], index) => {
        if (index === 0) {
            return css`
                @media screen and (min-width: 0px) {
                    ${getCssSkeletonHeightSize(iconSizeKey as string | number)}
                }
            `;
        }

        return css`
            @media ${theme.breakpoints[breakpointKey as BreakpointSizeType].min} {
                ${getCssSkeletonHeightSize(iconSizeKey as string | number)}
            }
        `;
    });
};

export const SkeletonWrapper = styled.span.withConfig({
    shouldForwardProp: (prop) => !['width', 'height', 'radius', 'variant'].includes(prop),
})<{
    width: number | string | object;
    height: number | string | object;
    radius?: number;
    variant: 'default' | 'negative' | 'positive';
}>`
    ${({ theme, width, height, radius, variant }) => css`
        display: inline-flex;
        background-color: ${getOpacity(theme.palette.color[variant].dark, 0.3)};

        border-radius: ${`${radius}rem`};
        overflow: hidden;
        position: relative;
        float: left;

        ${typeof width === 'number' || typeof width === 'string'
            ? getCssSkeletonWidthSize(width)
            : getResponsiveSkeletonWidthSize(width, theme).join('')}

        ${typeof height === 'number' || typeof height === 'string'
            ? getCssSkeletonHeightSize(height)
            : getResponsiveSkeletonHeightSize(height, theme).join('')}

        &:after {
            background: linear-gradient(90deg, transparent, ${theme.palette.color.default.opacity.high}, transparent);
            content: '';
            position: absolute;
            transform: translateX(-100%);
            inset: 0;
            animation: 1.5s linear 0.5s infinite normal none running wave;
        }

        @keyframes wave {
            0% {
                transform: translateX(-100%);
            }
            50% {
                transform: translateX(0%);
            }
            100% {
                transform: translateX(100%);
            }
        }
    `}
`;
