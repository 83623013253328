import styled, { css } from 'styled-components';
import { Wrapper as Button } from '../form/button/button.styled';
import { GapSizeType } from '../../../theme/type/gap-type';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['mobileBreakUnder', 'gapTop', 'gapBottom'].includes(prop),
})<{
    mobileBreakUnder?: BreakpointSizeType;
    gapTop?: GapSizeType;
    gapBottom?: GapSizeType;
}>(
    ({ theme, mobileBreakUnder, gapTop, gapBottom }) => css`
        margin-bottom: ${gapBottom ? theme.gap[gapBottom] : theme.gap.medium};
        margin-top: ${gapTop ? theme.gap[gapTop] : theme.gap.none};

        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].min} {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        `}

        ${!mobileBreakUnder &&
        css`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `}
    `,
);

export const Action = styled.div.withConfig({
    shouldForwardProp: (prop) => !['mobileBreakUnder'].includes(prop),
})<{
    mobileBreakUnder?: BreakpointSizeType;
}>(
    ({ theme, mobileBreakUnder }) => css`
        display: flex;
        align-items: center;

        ${Button} {
            margin-left: 0.8rem;

            @media ${theme.breakpoints.sm.min} {
                margin-left: 1.6rem;
            }

            &:first-child {
                margin-left: unset;
            }
        }

        ${mobileBreakUnder &&
        css`
            @media ${theme.breakpoints[mobileBreakUnder].max} {
                margin-top: ${theme.gap.small};
            }
        `}
    `,
);
