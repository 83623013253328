import React from 'react';
import { Table } from '../../table/table';
import { TableHead } from '../../table/head/table-head';
import { TableRow } from '../../table/row/table-row';
import { TableCell } from '../../table/cell/table-cell';
import { TableBody } from '../../table/body/table-body';
import Skeleton from '../skeleton';

interface SkeletonTableProps {
    rows?: number;
    cols?: number;
}

function getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const SkeletonTable: React.FC<SkeletonTableProps> = ({ rows = 10, cols = 8 }) => {
    return (
        <Table mobileBreakUnder="lg">
            <TableHead>
                <TableRow>
                    {Array.from({ length: cols }).map((_, index) => {
                        return (
                            <TableCell key={index}>
                                <Skeleton width={5} />
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {Array.from({ length: rows }).map((_, index) => {
                    return (
                        <TableRow key={index}>
                            {Array.from({ length: cols }).map((_cols, indexCols) => {
                                return (
                                    <TableCell key={indexCols}>
                                        <Skeleton width={getRandomNumber(7, 25)} />
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};
