import React from 'react';
import { SkeletonWrapper } from './skeleton.styled';

interface SkeletonProps {
    width?: number | string | object;
    height?: number | string | object;
    radius?: number;
    variant?: 'default' | 'negative' | 'positive';
}

const Skeleton: React.FC<SkeletonProps> = ({ width = 10, height = 1.6, radius = 0.5, variant = 'default' }) => {
    return <SkeletonWrapper width={width} height={height} radius={radius} variant={variant} />;
};

export default Skeleton;
